/**
 * @generated SignedSource<<494def414e57d10574bebd45c14e796b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventMembersPageQuery$variables = {
  slug: string;
};
export type EventMembersPageQuery$data = {
  readonly eventBySlug: {
    readonly id: string;
    readonly title: string;
    readonly viewerCanEditMembers: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"EventMembersFragment">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"UserAutocompleteFragment">;
};
export type EventMembersPageQuery = {
  response: EventMembersPageQuery$data;
  variables: EventMembersPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": "viewerCanEditMembers",
  "args": [
    {
      "kind": "Literal",
      "name": "action",
      "value": "ADD_EVENT_MEMBER"
    }
  ],
  "kind": "ScalarField",
  "name": "viewerCan",
  "storageKey": "viewerCan(action:\"ADD_EVENT_MEMBER\")"
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventMembersPageQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "UserAutocompleteFragment"
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Event",
        "kind": "LinkedField",
        "name": "eventBySlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EventMembersFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventMembersPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "EntityConnection",
        "kind": "LinkedField",
        "name": "entities",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EntityEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v3/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "entities(first:10)"
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Event",
        "kind": "LinkedField",
        "name": "eventBySlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": "viewerCanEdit",
            "args": [
              {
                "kind": "Literal",
                "name": "action",
                "value": "UPDATE_EVENT"
              }
            ],
            "kind": "ScalarField",
            "name": "viewerCan",
            "storageKey": "viewerCan(action:\"UPDATE_EVENT\")"
          },
          {
            "alias": "viewerCanTransferOwnership",
            "args": [
              {
                "kind": "Literal",
                "name": "action",
                "value": "TRANSFER_EVENT_OWNERSHIP"
              }
            ],
            "kind": "ScalarField",
            "name": "viewerCan",
            "storageKey": "viewerCan(action:\"TRANSFER_EVENT_OWNERSHIP\")"
          },
          {
            "alias": "canReadAgreement",
            "args": [
              {
                "kind": "Literal",
                "name": "action",
                "value": "READ_COMPETITION_RULE_AGREEMENT"
              }
            ],
            "kind": "ScalarField",
            "name": "viewerCan",
            "storageKey": "viewerCan(action:\"READ_COMPETITION_RULE_AGREEMENT\")"
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "EventMembershipConnection",
            "kind": "LinkedField",
            "name": "members",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EventMembershipEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EventMembership",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v7/*: any*/),
                      {
                        "alias": "viewerCanDelete",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "action",
                            "value": "REMOVE_EVENT_MEMBER"
                          }
                        ],
                        "kind": "ScalarField",
                        "name": "viewerCan",
                        "storageKey": "viewerCan(action:\"REMOVE_EVENT_MEMBER\")"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "entity",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v3/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          {
                            "kind": "TypeDiscriminator",
                            "abstractKey": "__isEntity"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "imageThumbnail",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "latest",
                            "value": true
                          }
                        ],
                        "concreteType": "EventRuleAgreementConnection",
                        "kind": "LinkedField",
                        "name": "ruleAgreements",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EventRuleAgreement",
                            "kind": "LinkedField",
                            "name": "nodes",
                            "plural": true,
                            "selections": [
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "ruleAgreements(latest:true)"
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "members(first:10)"
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "EventMembersFragment_members",
            "kind": "LinkedHandle",
            "name": "members"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "241a798aa0215963db6446adf95b5df5",
    "id": null,
    "metadata": {},
    "name": "EventMembersPageQuery",
    "operationKind": "query",
    "text": "query EventMembersPageQuery(\n  $slug: String!\n) {\n  ...UserAutocompleteFragment\n  eventBySlug(slug: $slug) {\n    title\n    id\n    viewerCanEditMembers: viewerCan(action: ADD_EVENT_MEMBER)\n    ...EventMembersFragment\n  }\n}\n\nfragment EntityProfilePicFragment_49kWkb on Entity {\n  __isEntity: __typename\n  displayName\n  imageThumbnail\n}\n\nfragment EventMembersFragment on Event {\n  id\n  viewerCanEdit: viewerCan(action: UPDATE_EVENT)\n  viewerCanEditMembers: viewerCan(action: ADD_EVENT_MEMBER)\n  viewerCanTransferOwnership: viewerCan(action: TRANSFER_EVENT_OWNERSHIP)\n  canReadAgreement: viewerCan(action: READ_COMPETITION_RULE_AGREEMENT)\n  members(first: 10) {\n    edges {\n      node {\n        id\n        kind\n        viewerCanDelete: viewerCan(action: REMOVE_EVENT_MEMBER)\n        entity {\n          __typename\n          id\n          username\n          displayName\n          ...EntityProfilePicFragment_49kWkb\n        }\n        ruleAgreements(latest: true) @ifAllowed {\n          nodes {\n            id\n          }\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment UserAutocompleteFragment on Query {\n  entities(first: 10) {\n    edges {\n      node {\n        __typename\n        id\n        kind\n        username\n        displayName\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bce3ad44323ba3c023786163b9e6964e";

export default node;
